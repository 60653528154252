import { useEffect, useState, useContext } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { Message } from 'primereact/message';
import { Login } from './Login';
import { Table } from './Table';
import { Header } from './Header';
import { AppContext } from '../context/AppContext';
import dayjs from 'dayjs';
import { BitdefenderPopup } from './BitdefenderPopup';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';

export const App = () => {
	const [isAuthorized, setIsAuthorized] = useState(false);
	const [bitdefenderPopupVisible, setBitdefenderPopupVisible] = useState(false);
	const isAuthenticated = useIsAuthenticated();
	const { accounts } = useMsal();
	const MicrosoftUserEmail = accounts[0]?.username;
	const { setUser, toast, dialogVisible, setDialogVisible, dialogInfo } = useContext(AppContext);

	useEffect(() => {
		const checkUserAuthorized = async () => {
			const options = {
				method: 'GET',
				headers: {
					Authorization: `Api-Key ${process.env.REACT_APP_BACKEND_API_KEY}`,
				},
			};

			try {
				const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users`, options);
				if (response.ok) {
					const data = await response.json();
					const userFound = data.data.find((user) => user.email === MicrosoftUserEmail);
					if (userFound) {
						const bitdefenderPopupUsers = process.env.REACT_APP_BITDEFENDER_POPUP_USERS.split(',');
						if (bitdefenderPopupUsers.includes(userFound.email)) {
							const lastCheckDate = localStorage.getItem('BitdefenderLastUpdate');
							if ((lastCheckDate && dayjs().isAfter(dayjs(lastCheckDate))) || !lastCheckDate) {
								setBitdefenderPopupVisible(true);
							}
						}
						setUser({ id: userFound.id, name: `${userFound.first_name} ${userFound.last_name}`, email: userFound.email });
						setIsAuthorized(true);
					}
				}
			} catch (error) {
				console.log(error);
			}
		};

		if (isAuthenticated) {
			checkUserAuthorized();
		}
	}, [isAuthenticated, MicrosoftUserEmail]);

	return (
		<>
			{isAuthenticated ? (
				<>
					<Header />
					{isAuthorized ? (
						<>
							<Table />
							{bitdefenderPopupVisible && <BitdefenderPopup setBitdefenderPopupVisible={setBitdefenderPopupVisible} />}
							<Toast ref={toast} position='top-right' />
							<Dialog header={dialogInfo.header} visible={dialogVisible} onHide={() => setDialogVisible(false)} draggable={false}>
								<p>{dialogInfo.content}</p>
							</Dialog>
						</>
					) : (
						<Message severity='error' text="Vous n'avez pas accès à l'application !" />
					)}
				</>
			) : (
				<Login />
			)}
		</>
	);
};
