import { createContext, useState, useRef } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
	const [appStatus, setAppStatus] = useState({ up: [], down: [] });
	const [user, setUser] = useState({ id: '', name: '', email: '' });
	const [workflowStatutsOptions, setWorkflowStatutsOptions] = useState({ tableOptions: [], modalOptions: [] });
	const [licenses, setLicenses] = useState([]);
	const toast = useRef(null);
	const [dialogVisible, setDialogVisible] = useState(false);
	const [dialogInfo, setDialogInfo] = useState({ header: '', content: '' });
	const openDialog = (header, content) => {
		setDialogInfo({ header, content });
		setDialogVisible(true);
	};

	return (
		<AppContext.Provider
			value={{
				appStatus,
				setAppStatus,
				user,
				setUser,
				toast,
				workflowStatutsOptions,
				setWorkflowStatutsOptions,
				licenses,
				setLicenses,
				dialogVisible,
				setDialogVisible,
				dialogInfo,
				setDialogInfo,
				openDialog,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};
